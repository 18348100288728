import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

export const sharedClassNames = ({tag, mt, mb, align, isHashable, hasBorder = false, ...props}) => className(`
    m-0
    ${isHashable ? 'hash-heading':''}
    ${tag} 
    ${tag}-override 
    ${mt ? `mt-${mt}`:''}
    ${mb ? `mb-${mb}`:''}
    ${align ? `text-${align}`:''}
    ${hasBorder ? 'border-bottom d-block' : ''}
    ${props.className || ''} 
`); 