import React from 'react'

import LogoComponent from '../parts/Navbar/NavbarBrandComponent';
import Container from './Container';
import SocialIcons from './SocialIcons';

import { getSvg } from '../userconfig';

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
import colors from '../colors.module.scss';
const { className } = new CssClassNames(styles);

export default function Footer({ linksItems = [], logo }) {
    return (
        <footer {...className(`${styles.footer} ${colors['bg-tertiary']} bg-tertiary pt-2 pb-7`)}>
            <Container>
                <div {...className(`${styles['wrapper-logos']} d-flex justify-content-between mb-1 mb-lg-n2`)}>
                    <div {...className('wrapper-logo')}>
                        {logo ? <LogoComponent href='/' parent='HomeFooter' placeholderLogo={getSvg(64, 64, '#ffffff', logo.p)} /> : null}
                    </div>
                    <SocialIcons {...className('align-self-center')} icons={[
                        {
                            url: 'https://www.linkedin.com/company/nae_es',
                            path: 'M3.454 8.772h3.508v12.227H3.454V8.772zm1.665-1.53h-.026C3.823 7.242 3 6.308 3 5.124 3 3.916 3.848 3 5.143 3c1.294 0 2.09.914 2.116 2.12 0 1.184-.822 2.122-2.14 2.122zM21 21h-3.978v-6.328c0-1.657-.623-2.786-1.991-2.786-1.047 0-1.629.762-1.9 1.498-.101.263-.085.63-.085 1V21H9.104s.051-11.21 0-12.228h3.942v1.919c.232-.84 1.492-2.037 3.502-2.037C19.04 8.654 21 10.414 21 14.2V21z',
                            kind: 'linkedin'
                        },
                        {
                            url: 'https://twitter.com/Nae_global',
                            path: 'M19.762 6.53a4.018 4.018 0 0 0 1.713-2.234 7.637 7.637 0 0 1-2.476.98A3.824 3.824 0 0 0 16.154 4c-2.152 0-3.897 1.808-3.897 4.039 0 .316.033.625.1.92-3.239-.17-6.11-1.775-8.034-4.22a4.141 4.141 0 0 0-.528 2.03c0 1.401.688 2.638 1.734 3.364a3.808 3.808 0 0 1-1.766-.505v.05c0 1.957 1.343 3.59 3.128 3.96a3.689 3.689 0 0 1-1.028.142c-.251 0-.497-.025-.733-.072.495 1.604 1.935 2.773 3.641 2.804A7.65 7.65 0 0 1 3 18.186 10.762 10.762 0 0 0 8.975 20c7.171 0 11.09-6.155 11.09-11.492 0-.176-.002-.35-.01-.522A8.06 8.06 0 0 0 22 5.894c-.7.321-1.45.538-2.238.636z',
                            kind: 'twitter'
                        },
                    ]} />
                </div>
                <div {...className(`d-flex justify-content-lg-between flex-column flex-lg-row`)}>
                    <span {...className(`${styles['footer-copyright']} p-2 mb-1 mb-lg-0`)}>© Copyright 2024 Nae</span>
                    <ul {...className(`${styles['footer-list']} d-flex flex-column flex-lg-row`)}>
                        {linksItems.map((item, i) => (
                            <li key={i}>
                                <a href={item.href} target="_blank" rel="noopener noreferrer" {...className('p-2')}>
                                    {item.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </Container>
        </footer>
    )
}
