import React, { memo, useRef } from 'react';
import striptags from 'striptags';
import parse from 'html-react-parser';
import { copyToClipboardValue } from '../../utils';

import Tooltip from '../Bootstrap/Tooltip';
import hashIcon from '../../assets/hash-icon.svg'

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
import { sharedClassNames } from './classGenerator';
import { sharedContainerClasses } from '../SCHeading/classGenerator';
const { className } = new CssClassNames(styles);

const SCHeading = memo(({ 
    content, 
    hash = '', 
    tag, 
    align, 
    isHashable, 
    children, 
    pt,
    pb,
    hashableContent = 'Link copied to clipboard.', 
    ...props }) => {

    const triggerTooltipRef = useRef(null); 

    const handleClick = (e) => {
        e.preventDefault();
        copyToClipboardValue(e.target.href);
    }

    tag = tag ? tag.trim().toLowerCase() : '';
    const Tag = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tag) ? tag : 'p';

    const contentFiltered = striptags(content, ['a', 'b', 'i', 'u', 's', 'strong', 'em', 'br']);

    const classes = sharedClassNames({ tag, isHashable ,...props });
    const paddingClasses = sharedContainerClasses(pt, pb);

    return (     
        <Tag className={`${classes.className} ${paddingClasses.className}`}>
            {parse(contentFiltered)}
            {isHashable &&
                <a ref={triggerTooltipRef} href={`#${hash}`} onClick={handleClick} aria-hidden="true" {...className(`anchor`)}>
                    <img src={hashIcon} alt='anchor'></img>
                </a>
            }
            {isHashable && triggerTooltipRef && 
                <Tooltip side='right' triggerRef={triggerTooltipRef}>{hashableContent}</Tooltip>
            }
        </Tag>
    )
});

export default SCHeading;