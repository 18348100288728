import React, {forwardRef} from 'react';
import { generatePaddingClasses } from '../../../utils';

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const Button = forwardRef(({
	tag = 'a',
	children,
	text = 'Button text...',
	kind = 'button',
	color = 'primary',
	outline = false,
	size = false,
	block = false,
	disabled = false,
	pt,
	pb,
	align,
	extraClass = '', 
	icon = '',
	iconAbsolute = true,
	href = null,
	download = false,
	onClick = null,
	gatsbyFile,
	gatsbyLink = false,
	tabIndex = null,
	title,
	target = null,
	classNameDiv = null,
	classFixHover = null,
	...props
}, ref) => { ;
	const onClickPrevent = e => {
		e.preventDefault();
		onClick(e)
	}

	let role, type;
	let Tag = tag !== 'button' ? 'a' : tag;
	const clickEvent = Tag === 'a' && onClick && !download ? onClickPrevent : onClick;
	let finalHref = Tag === 'a' && !href ? '#' : href;
	
	if (gatsbyFile && gatsbyFile.publicURL) {
		download = true;
		Tag = 'a';
		finalHref = gatsbyFile.publicURL;
	}
	if (gatsbyLink) {
		Tag = tag;
	}

	let classes = [
		'btn',
		'position-relative'
	];
	let classesDiv = ['position-relative'];

	if (Tag !== 'button') {
		role = kind;
	} else {
		type = kind;
	}
	
	if (color) {
		classes.push(`${outline ? `btn-outline-${color}` : `btn-${color}`}`);
	}
	if (size) {
		classes.push(`btn-${size}`);
	}
	if (block) {
		classes.push(`btn-block`);
	}

	classesDiv = generatePaddingClasses(pt, 't', classesDiv);
	classesDiv = generatePaddingClasses(pb, 'b', classesDiv);

	if (align) {
		classesDiv.push(`text-${align}`);
	}
	if (classNameDiv) {
		classesDiv.push(classNameDiv);
	}
	if (disabled) {
		classes.push(`disabled`);
	}
	if (icon) {
		classes.push(`iconized${size ? `-${size}` : ''} ${iconAbsolute ? 'iconized-absolute' : 'iconized-relative'}`);
	}
	if (extraClass) {
		classes.push(extraClass);
	}
	if(props.className) {
		classes.push(props.className);
	}
	
	return (
		<div {...className(`${classesDiv.join(' ')}`)} >
			<Tag {...className(`${classes.join(' ')}`)}
				{...{ type, 'data-role': role }}
				{...clickEvent && {onClick: clickEvent}}
				{...(finalHref && !gatsbyLink) && {href: finalHref}}
				{...gatsbyLink && {to: finalHref}}
				{...(Tag === 'a' && (!download || !gatsbyLink) ? { target: '_blank' } : {})}
				{...target && { target}}
				{...(ref ? {ref: ref}: {})}
				{...tabIndex && {tabIndex}}
				download={download}
				title={title}
				{...disabled && { disabled: 'disabled' }}
			>
				{icon ? (
					<>
						<span {...className(`icon ${iconAbsolute ? `icon-absolute bg-${color}` : ''}`)}>
							<img src={icon} alt='icon' />
						</span>
						<span>{children || text}</span>
					</>
				) : 
				(children || text)}
				
			</Tag>
			{clickEvent && <div onClick={clickEvent} {...className(`fix-hover position-absolute ${classFixHover ? classFixHover : ''}`)} />}
		</div>
	);
});

export default Button;