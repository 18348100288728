import React, { memo } from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import Heading from '../Heading';

const SCHeading = memo(props => {
    return (
        props.isHashable ? (
            <div  
                {...(props.isHashable ? { id: props.hash } : {})}
            >
                <Heading {...props} />
            </div>
        ) : (
            <Heading {...props} />
        )
    )
});

export default isSharedComponent(SCHeading, 'SCHeading');