import React from 'react';
import Placeholder from '../../../sharedComponents/assets/placeholder-image.png';

import styles from './styles.module.scss';

const ImageSlider = ({ url = null, gatsbyImage = {}, title, customSlide = null }) => {
    return (
        <div>
            <div className={customSlide || styles.slide} style={{ backgroundImage: `url(${gatsbyImage && gatsbyImage.publicURL ? gatsbyImage.publicURL : (url || Placeholder)})` }} role="img" aria-label={title} />
        </div>
    );
};

export default ImageSlider;